<style scoped>
    .summit-badge {
    height: 4rem;
    width: 4rem;
    float: right;
}
</style>
<template>
	<v-container fluid class="grey lighten-5 my-0 px-0  py-0">
		<q-agency-building-submenu></q-agency-building-submenu>
		<!-- Charts-->
		<div class="mx-5">
			<v-progress-linear color="primary" indeterminate v-if="agent == null"></v-progress-linear>
			<div v-if="agent">
				<v-row class="d-inline-flex mt-2 ml-2 pb-4">
					<div>
						<v-badge color="blue" :content="agent.ContractLevel" icon="fas fa-crown">
							<h5>{{ agent.AgentName }}</h5>
						</v-badge>
						<div class="agent-title mt-2 mb-3">
							{{ agent.Division }} - {{ agent.LeadershipLevel }}
							<span v-if="agent.ProducerLevel"> - {{agent.ProducerLevel}}</span>
						</div>
					</div>
					<div>
						<v-chip v-if="agent.Available" class=" ml-7" color="primary" text-color="white">{{ agent.Status
							}}</v-chip>
						<v-chip v-else class=" ml-7" color="warning" text-color="white">{{ agent.Status }}</v-chip>
					</div>
				</v-row>
				<QTabs dark>
					<v-tab @click="logTabActivty('Profile')">Profile</v-tab>
					<v-tab @click="logTabActivty('Licensing')">Licensing</v-tab>
					<v-tab @click="logTabActivty('Support')">Support</v-tab>
					<v-tab v-if="agent.Division == 'Field'" @click="logTabActivty('Stats')">Stats</v-tab>
					<v-tab v-if="agent.Division == 'Field'" @click="logTabActivty('Leads')">Leads</v-tab>
					<v-tab v-if="agent.Division == 'Field'" @click="logTabActivty('PendingPolicy')">Policies</v-tab>
					<v-tab @click="logTabActivty('Recruiting')" v-if="agent.IsAgencyOwner">Recruiting</v-tab>
					<!--<v-tab @click="logTabActivty('Documents')">Documents</v-tab>// removed per Jon's request prior to HQ Beta 1.2 launch -->
					<v-tab-item class="profile-info">
						<v-row class="text-left pa-3 cms" wrap>
							<v-col cols="12" md="7">
								<v-row wrap>
									<v-col cols="12" sm="6" md="5" lg="4">
										<QUserBadges :agent="agent" />
										<div v-if="agent.Avatar" class="agent_profile_photo">
											<v-img :aspect-ratio="1" :src="agent.Avatar"></v-img>
										</div>
									</v-col>
									<v-col cols="12" sm="6" md="7" lg="8">
										<v-list style="max-width:500px;">
											<v-alert type="warning" v-if="agent.Flanking && agent.Flanking.length"
												border="top" colored-border elevation="2">
												<div class="overline mb-4">This agent is being flanked by</div>
												<div v-for="flanking in agent.Flanking" :key="flanking.AgentID"
													@click="clickAgent(flanking.AgentCode)" style="cursor:pointer;">
													{{flanking.AgentName}}
													<span v-if="flanking.FlankExpDate != null">
														- {{formatReadableDate(flanking.FlankExpDate)}}
														({{relativeDate(flanking.FlankExpDate)}})
													</span>
												</div>
											</v-alert>
											<v-list-item v-if="agent.Status == 'Terminated'">
												<v-list-item-icon class="text-center" style="width:30px;">
													<v-icon color="q_green_1" style="margin:0 auto;">fas fa-ban</v-icon>
												</v-list-item-icon>
												<v-list-item-content>
													<v-list-item-title>
														{{ agent.Status }} : {{ agent.TerminationReason }}
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
											<v-divider v-if="agent.Status == 'Terminated'"></v-divider>
											<v-list-item>
												<v-list-item-icon class="text-center" style="width:30px;">
													<v-icon color="q_green_1" style="margin:0 auto;">fas
														fa-user</v-icon>
												</v-list-item-icon>
												<v-list-item-content>
													<v-list-item-title>
														<div class="overline">Agent Code:</div>
														<div>{{ agent.AgentCode }} </div>
														<div class="overline" style="padding-top:1em;">Agent NPN#:
														</div>
														<div>{{ agent.NPN }}</div>
														<div class="overline" style="padding-top:1em;">Opt ID:</div>
														<div>{{ agent.OptID }}</div>
														<div v-if="agent.ContractStartDt">
															<div class="overline" style="padding-top:1em;"><b>Start
																	Date:</b></div>
															<div>{{ formatDateCalendarWithYear(agent.ContractStartDt) }}
															</div>
														</div>
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
											<v-divider
												v-if="agent.FastTrack && agent.FastTrack.lightSpeedFastTrackCompleteDate"></v-divider>
											<v-list-item
												v-if="agent.FastTrack && agent.FastTrack.lightSpeedFastTrackCompleteDate">
												<v-list-item-icon>
													<v-icon color="q_green_1" style="margin:0 auto;">fas
														fa-calendar-check</v-icon>
												</v-list-item-icon>
												<v-list-item-content>
													<v-list-item-title
														v-if="agent.FastTrack && agent.FastTrack.lightSpeedFastTrackCompleteDate">
														<div style="display:inline-block; width:150px; ">FastTrack
															Completion Date:</div>
														<div style="display:inline-block; width:150px; ">{{
															formatDateCalendarWithYear(agent.FastTrack.lightSpeedFastTrackCompleteDate)
															}}</div>
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
											<v-divider></v-divider>
											<v-list-item>
												<v-list-item-icon class="text-center" style="width:30px;">
													<v-icon style="margin:0 auto;" color="q_green_1">fas
														fa-crown</v-icon>
												</v-list-item-icon>
												<v-list-item-content>
													<v-list-item-title>
														<div class="overline text-wrap" style="display:inline-block:">
															Leadership Level:</div>
														<div style="display:inline-block:"> {{ agent.LeadershipLevel
															}}<span v-if="agent.ProducerLevel">,
																{{agent.ProducerLevel}}</span></div>
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
											<v-divider
												v-if="(agent.DoingBusinessAsName || agent.DoingBusinessAsNPN) && !agent.HideDBA"></v-divider>
											<v-list-item
												v-if="(agent.DoingBusinessAsName || agent.DoingBusinessAsNPN) && !agent.HideDBA">
												<v-list-item-icon class="text-center" style="width:30px;">
													<v-icon color="q_green_1" style="margin:0 auto;">fas
														fa-briefcase</v-icon>
												</v-list-item-icon>
												<v-list-item-content>
													<v-list-item-title>
														<div v-if="agent.DoingBusinessAsName" class="overline"
															style="padding-top:1em;">DBA: </div>
														<div>{{ agent.DoingBusinessAsName }}</div>
														<div v-if="agent.DoingBusinessAsNPN" class="overline"
															style="padding-top:1em;">DBA NPN #: </div>
														<div>{{ agent.DoingBusinessAsNPN }}</div>
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
											<v-divider></v-divider>
											<v-list-item>
												<v-list-item-icon class="text-center" style="width:30px;">
													<v-icon color="q_green_1" style="margin:0 auto;">fas
														fa-envelope</v-icon>
												</v-list-item-icon>
												<v-list-item-content>
													<v-list-item-title>
														<a :href="'mailto:' + agent.AgentEmail">{{ agent.AgentEmail
															}}</a><br>
														<span v-if="agent.BusinessEmail">
															Business : <a style="padding-right: 4px;"
																:href="'mailto:' + agent.BusinessEmail">{{
																agent.BusinessEmail }}</a>
															<v-tooltip bottom>
																<template v-slot:activator="{ on, attrs }">
																	<v-icon color="#999999" dark v-bind="attrs"
																		v-on="on">
																		fas fa-question-circle
																	</v-icon>
																</template>
																<span>Business email is for DTC use only.</span>
															</v-tooltip>
														</span>
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
											<v-divider></v-divider>
											<v-list-item>
												<v-list-item-icon class="text-center" style="width:30px;">
													<v-icon color="q_green_1" style="margin:0 auto;">fas
														fa-phone</v-icon>
												</v-list-item-icon>
												<v-list-item-content>
													<v-list-item-title>
														<a :href="'tel:' + agent.AgentPhone">{{ agent.AgentPhone
															}}</a><br>
														<span v-if="agent.BusinessPhone">
															Business : <a style="padding-right: 4px;"
																:href="'tel:' + agent.BusinessPhone">{{
																agent.BusinessPhone }}</a>
															<v-tooltip bottom>
																<template v-slot:activator="{ on, attrs }">
																	<v-icon color="#999999" dark v-bind="attrs"
																		v-on="on">
																		fas fa-question-circle
																	</v-icon>
																</template>
																<span>Business phone is for DTC use only.</span>
															</v-tooltip>
															<br>
														</span>
														<span v-if="agent.SummitPhone">
															Summit : <a style="padding-right: 4px;"
																:href="'tel:' + agent.SummitPhone">{{ agent.SummitPhone
																}}</a>
															<v-tooltip bottom>
																<template v-slot:activator="{ on, attrs }">
																	<v-icon color="#999999" dark v-bind="attrs"
																		v-on="on">
																		fas fa-question-circle
																	</v-icon>
																</template>
																<span>Summit phone is for Summit notifications use
																	only.</span>
															</v-tooltip>
															<br>
														</span>
														<span v-if="agent.CellPhone">Cell : <a
																:href="'tel:' + agent.CellPhone">{{ agent.CellPhone
																}}</a></span>
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
											<v-divider></v-divider>
											<v-list-item>
												<v-list-item-icon class="text-center" style="width:30px;">
													<v-icon color="q_green_1" style="margin:0 auto;">fas
														fa-address-card</v-icon>
												</v-list-item-icon>
												<v-list-item-content>
													<v-list-item-title>
														{{ agent.StreetAddress }}<br>
														{{ agent.City }}, {{ agent.State }} {{ agent.Zip }}<br>
														<span v-if="agent.County">County : {{agent.County}}</span>
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
											<v-divider></v-divider>
											<v-list-item>
												<v-list-item-icon>
													<v-icon color="q_green_1" style="margin:0 auto;">fas
														fa-users</v-icon>
												</v-list-item-icon>
												<v-list-item-content>
													<v-list-item-title class="overline">Memberships and
														subscriptions</v-list-item-title>
													<v-list-item-subtitle>
														<v-list dense>
															<v-list-item v-if="agent.IsAdvisoryBoardMember"
																class="body-2">
																<v-icon color="q_green_1">far fa-check-circle</v-icon>
																<v-list-item-content class="pl-3">
																	Advisory Board Member
																</v-list-item-content>
															</v-list-item>
															<v-list-item v-if="agent.IsOwnerCircleMember"
																class="body-2 ">
																<v-icon color="q_green_1">far fa-check-circle</v-icon>
																<v-list-item-content class="pl-3">Owner's Circle
																	Member</v-list-item-content>
															</v-list-item>
															<v-list-item v-if="agent.HasSherpaInd" class="body-2 ">
																<v-icon color="q_green_1">far fa-check-circle</v-icon>
																<v-list-item-content
																	class="pl-3">Sherpa</v-list-item-content>
															</v-list-item>
															<v-list-item v-if="agent.HasManagerAccess" class="body-2">
																<v-icon color="q_green_1">far fa-check-circle</v-icon>
																<v-list-item-content class="pl-3">Virtual
																	Assistant</v-list-item-content>
															</v-list-item>
															<v-list-item v-if="agent.PCSParticipant"
																class="body-2 pt-2">
																<v-icon color="q_green_1">far fa-check-circle</v-icon>
																<v-list-item-content class="pl-3">PCS Participant <br />
																	Start Date: {{ agent.PCSStartDate
																	}}</v-list-item-content>
															</v-list-item>
															<v-list-item v-if="agent.PCSAgentInd" class="body-2 pt-2">
																<v-icon color="q_green_1">far fa-check-circle</v-icon>
																<v-list-item-content class="pl-3">PCS Agent<br />
																	{{ agent.PCSAgentStartDate }} - {{
																	agent.PCSAgentEndDate }}</v-list-item-content>
															</v-list-item>
															<v-list-item v-if="agent.DFLCertifiedInd"
																class="body-2 pt-2">
																<v-icon color="q_green_1">far fa-check-circle</v-icon>
																<v-list-item-content class="pl-3">DFL
																	Certified</v-list-item-content>
															</v-list-item>
															<v-list-item v-if="agent.AnnuityCertifiedInd"
																class="body-2 pt-2">
																<v-icon color="q_green_1">far fa-check-circle</v-icon>
																<v-list-item-content class="pl-3">Annuity
																	Certified</v-list-item-content>
															</v-list-item>
															<v-list-item v-if="agent.IULCertifiedInd"
																class="body-2 pt-2">
																<v-icon color="q_green_1">far fa-check-circle</v-icon>
																<v-list-item-content class="pl-3">IUL
																	Certified</v-list-item-content>
															</v-list-item>
															<v-list-item v-if="agent.SeasonedNewAgentInd"
																class="body-2 pt-2">
																<v-icon color="q_green_1">far fa-check-circle</v-icon>
																<v-list-item-content class="pl-3">Seasoned New
																	Agent</v-list-item-content>
															</v-list-item>
															<v-list-item v-if="agent.QuilityRXGroupNumber"
																class="body-2 pt-2">
																<v-icon color="q_green_1">far fa-check-circle</v-icon>
																<v-list-item-content class="pl-3">QuilityRx Agent <br />
																	GroupCode: {{agent.QuilityRXGroupNumber}}<br>
																	Start Date: {{ formatDate(agent.QuilityRXGroupDate)
																	}}</v-list-item-content>
															</v-list-item>
															<v-list-item v-if="agent.CurrentSummitStep"
																class="body-2 pt-2">
																<v-icon color="q_green_1">far fa-check-circle</v-icon>
																<v-list-item-content class="pl-3">
																	Summit <br> {{agent.CurrentSummitStep.StepCategory}}
																	: {{ ordinalSummitStep }} - {{
																	agent.CurrentSummitStep.StepTitle }}
																</v-list-item-content>
															</v-list-item>
															<v-list-item v-if="appsIndicator.value" class="body-2 pt-2">
																<v-icon color="q_green_1">far fa-check-circle</v-icon>
																<v-list-item-content class="pl-3">
																	{{ appsIndicator.label }}<br />
																	Earn Date: {{ formatDate(appsIndicator.dateEarned)
																	}}
																</v-list-item-content>
															</v-list-item>
														</v-list>
													</v-list-item-subtitle>
												</v-list-item-content>
											</v-list-item>
										</v-list>
									</v-col>
								</v-row>
							</v-col>
							<v-col cols="12" md="5" class="px-4">
								<q-agent-upline :agent="agent"></q-agent-upline>
							</v-col>
						</v-row>
					</v-tab-item>
					<v-tab-item class="licensing-info">
						<div class="pa-4">
							<h2>Licensing</h2>
							<q-agent-licensing :agent="agent"></q-agent-licensing>
						</div>
					</v-tab-item>
					<v-tab-item class="support-tickets">
						<div class="pa-4">
							<q-agent-support-tickets-data-table :agent="agent"
								title="Support Tickets"></q-agent-support-tickets-data-table>
						</div>
					</v-tab-item>
					<v-tab-item v-if="agent.Division == 'Field'" class="stats">
						<QTabs class="q-text-left" background-color="q_symm_blue" dark>
							<v-tab>
								Production Scorecard
							</v-tab>
							<v-tab>
								Recruiting Scorecard
							</v-tab>
							<!--<v-tab>
                                Leads Scorecard
                            </v-tab>//-->
							<v-tab>
								Events Scorecard
							</v-tab>
							<v-tab>
								QuilityRx Scorecard
							</v-tab>
							<v-tab>
								HQ Scorecard
							</v-tab>
							<v-tab-item class="pa-2">
								<score-cards-new-business :show-submenu="false"
									:allowAgentLookup="false"></score-cards-new-business>
							</v-tab-item>
							<v-tab-item class="pa-2">
								<score-cards-recruiting :show-submenu="false"
									:allowAgentLookup="false"></score-cards-recruiting>
							</v-tab-item>
							<!--<v-tab-item class="pa-2">
                                <score-cards-leads :show-submenu="false" :allowAgentLookup="false"></score-cards-leads>
                            </v-tab-item>//-->
							<v-tab-item class="pa-2">
								<score-cards-events :show-submenu="false"
									:allowAgentLookup="false"></score-cards-events>
							</v-tab-item>
							<v-tab-item class="pa-2">
								<q-score-card-quility-rx :show-submenu="false"
									:allowAgentLookup="false"></q-score-card-quility-rx>
							</v-tab-item>
							<v-tab-item class="pa-2">
								<score-cards-hq :show-submenu="false" :allowAgentLookup="false"></score-cards-hq>
							</v-tab-item>
						</QTabs>
					</v-tab-item>
					<v-tab-item v-if="agent.Division == 'Field'" class="leads">
						<QTabs class="q-text-left" background-color="q_symm_blue" dark>
							<v-tab>
								Leads
							</v-tab>
							<v-tab>
								Lighthouse Lead Orders
							</v-tab>
							<v-tab v-if="hasPermission('agent:EconomyLeadOrders')">
								Digital Economy Lead Orders
							</v-tab>
							<v-tab>
								Recruiting Lead Orders
							</v-tab>
							<v-tab>
								Call-in Lead Orders
							</v-tab>
							<v-tab v-if="agent.IsAgencyOwner">
								Baseshop Lead Orders
							</v-tab>
							<v-tab-item class="pa-2">
								<lead-counts :agent="agent"></lead-counts>
							</v-tab-item>
							<v-tab-item class="pa-2">
								<q-digital-lead-order-data-table :no-actions="true" :lead-types="['Lighthouse']"
									ref="detail_lighthouse_lead_orders" :agent-code="agent.AgentCode" title="Lighthouse"
									key="agent_detail_lighthouse_lead_orders"></q-digital-lead-order-data-table>
							</v-tab-item>
							<v-tab-item v-if="hasPermission('agent:EconomyLeadOrders')" class="pa-2">
								<q-digital-lead-order-data-table :no-actions="true" :lead-types="['Economy']"
									:agent-code="agent.AgentCode"
									title="Digital Economy Leads"></q-digital-lead-order-data-table>
							</v-tab-item>
							<v-tab-item class="pa-2">
								<q-dx-lead-order-data-table :no-actions="true" :lead-types="['Recruiting']"
									ref="recruiting_lead_order_datatable" :agent-code="agent.AgentCode"
									title="Recruiting Lead Orders"
									key="agent_detail_recruiting_lead_orders"></q-dx-lead-order-data-table>
							</v-tab-item>
							<v-tab-item class="pa-2">
								<q-digital-lead-order-data-table :no-actions="true" :lead-types="['Call In']"
									ref="call_in_lead_orders" :agent-code="agent.AgentCode" title="Call-In"
									key="agent_call_in_lead_orders"></q-digital-lead-order-data-table>
							</v-tab-item>
							<v-tab-item class="pa-2" v-if="agent.IsAgencyOwner">
								<q-baseshop-lead-order-report-table title="Baseshop Lead Orders"
									:agent-code="agent.AgentCode"></q-baseshop-lead-order-report-table>
							</v-tab-item>
						</QTabs>
					</v-tab-item>
					<v-tab-item v-if="agent.Division == 'Field'" class="pending_policies">
						<QTabs class="q-text-left" background-color="q_symm_blue" dark>
							<v-tab>
								Pending Policies
							</v-tab>
							<v-tab>
								Placed Policies
							</v-tab>
							<v-tab>
								Conserved Policies
							</v-tab>
							<v-tab>
								Applications
							</v-tab>
							<v-tab-item class="pa-2">
								<q-pending-policy-data-table :agent="agent" :rows="25"
									title="Pending Policies"></q-pending-policy-data-table>
							</v-tab-item>
							<v-tab-item>
								<q-placed-policy-data-table :agent="agent" :rows="25"
									title="Placed Policies"></q-placed-policy-data-table>
							</v-tab-item>
							<v-tab-item>
								<q-conserved-policies-drilldown :agent-code="agentcode" :rows="25"
									title="Conserved Policies"></q-conserved-policies-drilldown>
							</v-tab-item>
							<v-tab-item>
								<q-applications-table :agent-code="agentcode" :rows="25"
									title="Applications"></q-applications-table>
							</v-tab-item>
						</QTabs>
					</v-tab-item>
					<v-tab-item v-if="agent.IsAgencyOwner" class="recruiting">
						<QTabs v-if="agent && agent.IsAgencyOwner" class="q-text-left" background-color="q_symm_blue"
							dark>
							<!-- <v-tab>Recruiting Dashboard</v-tab> -->
							<v-tab>
								<span v-if="agent.LeadershipLevel != 'Agency Owner'">Total Agency Directory</span>
								<span v-else>Base Shop Directory</span>
							</v-tab>
							<v-tab v-if="agent.Division == 'Field'">New Agent Stats</v-tab>
							<v-tab v-if="agent.Division == 'Field'">Onboarding Stats</v-tab>
							<v-tab>Hierarchy</v-tab>
							<!-- <v-tab-item class="pa-2">
                                <AoRecruitingDashboard :agent-code="agent.AgentCode"></AoRecruitingDashboard>
                            </v-tab-item> -->
							<v-tab-item class="pa-2">
								<q-agent-downline-directory-table :rows="20" :show-all="true"
									:title="agent.AgentName + ' Agency'"
									:agent="agent"></q-agent-downline-directory-table>
							</v-tab-item>
							<v-tab-item v-if="agent.Division == 'Field'" class="pa-2">
								<q-agent-recruiting-stats-new-agent :agent="agent"></q-agent-recruiting-stats-new-agent>
							</v-tab-item>
							<v-tab-item v-if="agent.Division == 'Field'" class="pa-2">
								<h3>Onboarding Stats Coming Soon!</h3>
							</v-tab-item>
							<v-tab-item class="pa-2">
								<q-agency-structure-chart :agent="agent"></q-agency-structure-chart>
							</v-tab-item>
						</QTabs>
						<div class="pa-4">
						</div>
					</v-tab-item>
					<!--<v-tab-item class="documents">
                        <div class="pa-4">
                            <q-agent-documents :agent="agent"></q-agent-documents>
                        </div>
                    </v-tab-item>//-->
				</QTabs>
			</div>
		</div>
	</v-container>
</template>
<script>
import QAgencyStructureChart from '@/components/charts/QAgencyStructureChart.vue'
import QAgencyBuildingSubmenu from '@/components/navigation/Submenus/QAgencyBuildingSubmenu.vue';
import QDownlineStats from '@/components/stats/QDownlineStats.vue';
import QuilityAPI from '@/store/API/QuilityAPI.js'
import Agent from '@/store/Models/Agent'
import QAgentStats from '@/components/stats/QAgentStats.vue';
import QBaseshopStats from '@/components/stats/QBaseshopStats.vue';
import QAgentUpline from '@/components/lists/QAgentUpline.vue';
import QPersonalProductionStats from '@/components/stats/QPersonalProductionStats.vue';
import QAgentDirectoryTable from '@/components/datatables/QAgentDirectoryTable.vue';
import QAgentLicensing from "@/components/utils/QAgentLicensing.vue"
import QMyProfileSubmenu from '@/components/navigation/Submenus/QMyProfileSubmenu.vue';
import QAgentDownlineDirectoryTable from '@/components/datatables/QAgentDownlineDirectoryTable.vue';
import QLeaderboardDataTableWrapper from '@/components/datatables/QLeaderboardDataTableWrapper.vue'
import QAgentDocuments from '@/components/agent_admin/QAgentDocuments.vue'
import QAgentSupportTicketsDataTable from '@/components/datatables/QAgentSupportTicketsDataTable.vue'
import QAgentRecruitingStatsNewAgent from '@/components/stats/QAgentRecruitingStatsNewAgent.vue';
import LeadCounts from '@/views/Leads/LeadCounts.vue'
import QPendingPolicyDataTable from '@/components/datatables/QPendingPolicyDataTable.vue';
import QPlacedPolicyDataTable from '@/components/datatables/QPlacedPolicyDataTable.vue';
import ScoreCardsNewBusiness from '@/views/MyStats/ScoreCards_NewBusiness.vue'
import ScoreCardsLeads from '@/views/MyStats/ScoreCards_Leads.vue'
import ScoreCardsRecruiting from '@/views/MyStats/ScoreCards_Recruiting.vue'
import ScoreCardsEvents from '@/components/stats/scorecards/QScoreCards_EventsAll.vue'
import ScoreCardQuilityRx from '@/views/MyStats/QScoreCards_QuilityRx.vue';
import QDxLeadOrderDataTable from '@/components/datatables/LeadOrders/QDxLeadOrderDataTable.vue'
import QDigitalLeadOrderDataTable from '@/components/datatables/LeadOrders/QDigitalLeadOrderDataTable.vue'
import ScoreCardsHq from '@/views/MyStats/ScoreCards_HQ.vue'
import QTabs from '../../../components/navigation/QTabs.vue'
import QUserBadges from '@/components/profile/QUserBadges.vue'
import QConservedPoliciesDrilldown from '@/components/stats/scorecards/QConservedPoliciesDrilldown'
import QApplicationsTable from '@/components/datatables/QApplicationsTable'
import QBaseshopLeadOrderReportTable from '@/components/datatables/LeadOrders/QBaseshopLeadOrderReportTable.vue';
import AoRecruitingDashboard from '@/modules/AoRecruitingDashboard/views/AoRecruitingDashboard.vue';

export default {
    name: "AgentDetail/AgencyOwner",
    data() {
        return {
            agentcode: null,
            agentdownline: {},
            current_agent: null,
            stickyTop: 0,
            date_period: 'monthly',
            editUpline: false,
            note_count: 0,
        };
    },
    mounted: function() {
        this.reset();
    },
    computed: {
        'agent': function() {
            return this.$store.state.entities.agent.currentAgent;
        },
        loading: function() {
            return this.$store.state.entities.agent.loading;
        },
        ordinalSummitStep: function() {
            const english_ordinal_rules = new Intl.PluralRules("en", { type: "ordinal" })
            const suffixes = {
                one: "st",
                two: "nd",
                few: "rd",
                other: "th"
            }
            const currentStep = parseInt(this.agent.CurrentSummitStep.StepCategoryOrder) + 1
            const suffix = suffixes[english_ordinal_rules.select(currentStep)]

            return `${currentStep}${suffix}`
        },
        appsIndicator() {
            if (this.agent.AgentFlags?.Twenty_Five_Apps?.value) {
                return {
                    ...this.agent.AgentFlags.Twenty_Five_Apps,
                    label: 'Twenty Five Apps',
                };
            } else if (this.agent.AgentFlags?.Fifteen_Apps?.value) {
                return {
                    ...this.agent.AgentFlags.Fifteen_Apps,
                    label: 'Fifteen Apps',
                };
            }
            return {
                ...this.agent.AgentFlags?.Six_Apps,
                label: 'Six Apps',
            };
        },
    },
    methods: {
        showAgent(agent) {
            this.current_agent = agent
        },
        reset: function() {
            this.agentcode = this.$route.params.agentcode;
            this.note_count = 0;
            QuilityAPI.getMyAgent(this.agentcode, ['LeadershipLevel', 'Avatar', 'LastLoginDate', 'HideDBA', 'CurrentSummitBadge', 'FastTrack', 'CurrentSummitStep', 'Flanking', 'AgentFlags']);
        },
        getDownline: function() {
            return this.recursiveDownline(this.agent.Downline)
        },
        getAgentDownline: function(AgentCode) {
            var g = this
            QuilityAPI.getAgentDownline(AgentCode, [], function(json) {
                g.agentdownline[AgentCode] = json
            });
        },
        recursiveDownline: function(children) {
            var str = ""
            for (var i = 0; i < children.length; i++) {
                str = str + "<a href='/admin/agents/" + children[i].AgentCode + "'>" + children[i].AgentName + "</a><br>";
                if (children[i].Downline == null) continue;
                if (children[i].Downline.length > 0) {
                    str = str + this.recursiveDownline(children[i].Downline);
                }
            }
            return str;
        },
        loadAgent(agentcode) {
            this.$router.push('/admin/agents/' + agentcode)
        },
        updateNoteCount(notes) {
            this.note_count = notes.meta.note_count;
        },
        clickAgent: function(ac) {
            this.$router.push('/agents/' + ac)
        },
    },
    watch: {
        '$route.params.agentcode': function(newV, oldV) {
            if (typeof newV != 'undefined' && this.agentcode != newV) {
                this.reset();
            }
        },
        agent() {
            this.setPageTitle((this.agent && this.agent.AgentName) || 'Agent Detail')
        },
    },
    components: {
        QAgentStats,
        QDownlineStats,
        QPersonalProductionStats,
        QBaseshopStats,
        QAgentUpline,
        QAgencyStructureChart,
        QAgentDirectoryTable,
        QAgentLicensing,
        QAgentDownlineDirectoryTable,
        QLeaderboardDataTableWrapper,
        QAgentDocuments,
        QAgentSupportTicketsDataTable,
        QAgencyBuildingSubmenu,
        QAgentRecruitingStatsNewAgent,
        LeadCounts,
        QPendingPolicyDataTable,
        QPlacedPolicyDataTable,
        ScoreCardsNewBusiness,
        ScoreCardsLeads,
        ScoreCardsRecruiting,
        ScoreCardsEvents,
        ScoreCardQuilityRx,
        QDxLeadOrderDataTable,
        ScoreCardsHq,
        QTabs,
        QUserBadges,
		QConservedPoliciesDrilldown,
		QApplicationsTable,
        QDigitalLeadOrderDataTable,
        QBaseshopLeadOrderReportTable,
        AoRecruitingDashboard
    }
};
</script>
<style scoped>
blockquote {
    margin-left: 50px;
}
</style>
